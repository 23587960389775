import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image/withIEPolyfill"


export default function Publication({ location, data }) {
  const attachments = data.publication.file.attachments
                          .filter(i => i.__typename !== "MarkdownRemark")
                          .sort((a, b) => { return a.name > b.name })
  let page_heading = data.publication.frontmatter.title
  if (data.publication.headings.length > 0) {
    if (data.publication.headings[0].depth === 1) {
      page_heading = data.publication.headings[0].value
    }
  }
  return (
    <Layout location={location} title={page_heading}>
      <article className="publication">
        <div className="publication--header">
          <header className="page--header">
            <h1>{page_heading}</h1>
            <p>{data.publication.frontmatter.publication_type}, {data.publication.frontmatter.publication_date}</p>
          </header>
        </div>
        <div className="publication--body">
          <div dangerouslySetInnerHTML={{ __html: data.publication.html }} />
        </div>
        <aside className="publication--aside">
          <header className="sr-only">About this text</header>
          <dl className="publication--metadata">
            <dt>Author</dt>
            <dd>{data.publication.frontmatter.author}</dd>
            {data.publication.frontmatter.exhibition !== null &&
             <div>
               <dt>Exhibition</dt>
               <dd><Link to={ data.publication.frontmatter.exhibition + '#exhibition-slideshow' }>View works</Link></dd>
             </div>
            }
            <dt>Time to read</dt>
            <dd>{data.publication.timeToRead} minutes</dd>
            {attachments.length > 0 &&
             <div>
               <dt>Attachments</dt>
               {attachments.map((node, index) => {
                 if (node.extension === "pdf") {
                   return (
                     <dd key={node.id}><a href={node.publicURL} title={`Download ${node.extension}`}>{decodeURI(node.name)}</a> ({node.extension})</dd>
                   )
                 } else {
                   return (
                     <dd key={node.id}><Img fluid={node.thumb.fluid} alt={node.thumb.fields.caption}></Img></dd>
                   )
                 }
               })}
             </div>
            }
          </dl>
        </aside>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query Publication ($slug: String!) {
    publication: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        publication_date(formatString: "Mo MMMM YYYY")
        publication_type
        exhibition
      }
      headings {
        depth
        value
      }
      timeToRead
      file: parent {
        attachments: children {
          id
          ... on File {
            id
            name
            thumb: childImageSharp {
              id
              fluid(fit: INSIDE, maxHeight: 216, maxWidth: 216) {
                base64
                originalName
                srcSet
                aspectRatio
              }
              fields {
                caption
              }
            }
            publicURL
            extension
          }
        }
      }
    }
  }
`
